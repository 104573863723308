
const $ = require("jquery");




if (typeof window !== `undefined`) {

    $(document).ready(function () {

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'someKey': 'someValue'
        })
//        window.dataLayer = window.dataLayer || [];

        gtag('js', new Date());

        gtag('config', 'G-8NH5QHJ6E9');


    });

}


function gtag() {
    window.dataLayer.push(arguments);
}