import React from "react"
import i18next from '../i18n/config';

import ImgItCoffee from "../img/coffee-4042935_1920.png";


export default function ItalianSpeciality(props)
{



    return (
            <React.Fragment>



                <section id="italianspeciality">
                <div class="box">
                    <h2>{i18next.t('ish2')}</h2>
                    <div class="line"></div>
                    <p> {i18next.t('isp1')}
                        <br /><br />
                        {i18next.t('isp2')}
                        <br /><br />
                        {i18next.t('isp3')}
                    </p></div>

                    <div class="box">

                    <div class="italian">
                        <img src={ImgItCoffee} alt="Italian Speciality coffee" />
                    </div>    </div>
                </section>






            </React.Fragment>

            )


}