import React from "react"
import i18next from '../i18n/config';

import ImgRip from "../img/Zasob13.png"
import ImgHar from "../img/Zasob14.png"
import ImgDry from "../img/Zasob15.png"
import ImgSor from "../img/Zasob16.png"
import ImgRoa from "../img/Zasob17.png"
import ImgRoad from "../img/Zasob18.png"


export default function TraditionalManufacturing(props)
{



    return (
            <React.Fragment>




                <section id="traditionalmanufacturing">
                    <h2>{i18next.t('tmh2')}</h2>
                    <div class="line"></div>
                    <h3>{i18next.t('tmh3')}</h3>
                    <p>{i18next.t('tmp1')}
                        <br /><br />
                        {i18next.t('tmp2')}
                    </p>
                    <h3 class="h3prod">{i18next.t('tmh3b')}</h3>
                    <div class="features">
                        <div class="feature">
                            <img src={ImgRip} alt="Ripening" />
                            <p>{i18next.t('frip')}</p>
                        </div>
                        <div class="feature">
                            <img src={ImgHar} alt="Harvest" />
                            <p>{i18next.t('fhar')}</p>
                        </div>
                        <div class="feature">
                            <img src={ImgDry} alt="Drying" />
                            <p>{i18next.t('fdry')}</p>
                        </div>
                        <div class="feature">
                            <img src={ImgSor} alt="Sorting" />
                            <p>{i18next.t('fsor')}</p>
                        </div>
                        <div class="feature">
                            <img src={ImgRoa} alt="Roasting" />
                            <p>{i18next.t('froa')}</p>
                        </div>
                        <div class="feature">
                            <img src={ImgRoad} alt="Roasted coffee" />
                            <p>{i18next.t('froad')}</p>
                        </div>
                    </div>
                </section>






            </React.Fragment>

            )


}