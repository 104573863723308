import React from "react"


//import IMGszklanki from "../img/szklanki.png"


import i18next from '../i18n/config'
        const $ = require("jquery")



export default class ItalianSpecialityGallery extends React.Component {

//export default function ItalianSpecialityGallery(props)


    MoreClick()
    {
        var Button = $("#ItalianSpecialityGallery_moreButton");
        if (Button.html() == "More") {
            $(".ItalianSpecialityGalleryHiden").show(777);
            Button.html("Less");
        } else {
            Button.html("More");
            $(".ItalianSpecialityGalleryHiden").hide("777", function () {

            });

            var pos = ($(".gallery").offset().top);
            pos = pos - 255;
            $('html, body').animate({
                scrollTop: pos
            }, 500, function () {
            });

        }







    }

    render() {
        return (
                <React.Fragment>


                    <div class="gallery fadeInmw">



                        {i18next.t('ItalianSpecialityGallery').map((itemTmp, i) => {
                                const p = require("../img/" + itemTmp.img + ".png");
                                var classAll = "  item ItalianSpecialityGalleryItem";
                                if (i > 3)
                                    classAll = "ItalianSpecialityGalleryHiden item ItalianSpecialityGalleryItem";
                                return (
                                        <div className={classAll}>
                                            <img src=
                                                 {(p)}
                                                 alt={(itemTmp.imgAlt)}
                                                 />
                                            <h4>
                                                {(itemTmp.name)}
                                            </h4>
                                            <p>
                                                {(itemTmp.info)}
                                            </p>
                                        </div>

                                        )
                            })}




                    </div>

                    <div class="gallerybutton"><button id="ItalianSpecialityGallery_moreButton" onClick=
                                                                                                                                                                                                                                                                                                                                                                                                                                                        {this.MoreClick}
                                                       >More</button></div>

                </React.Fragment>

                )

    }
}