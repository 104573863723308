import React from "react"


import ImgLogo from "../img/logo.png"

import i18next from '../i18n/config';

export default function TheArtOfRoasting(props)
{



    return (
            <React.Fragment>



                <section id="theartofroasting">
                    <h2>{i18next.t('tawh2')}</h2>
                    <div class="line"></div>
                    <p>{i18next.t('tawp')}</p>
                </section>



            </React.Fragment>

            )


}