import React from "react"


import ImgBeans1 from "../img/coffee-beans-5712774_1920.png";
import ImgBeans2 from "../img/coffee-66850_1280.png";
import ImgBeans3 from "../img/stock-photo-top-view-serious-bearded-worker-near-coffee-roaster-controlling-level-of-grain-roasting-revise-755543239.png";

import i18next from '../i18n/config';

export default function TheArtOfRoastingGallery(props)
{



    return (
            <React.Fragment>

                <div class="galleryroasting fadeInmw">
                    <div><img src={ImgBeans1} alt="Coffee Beans 1" /></div>
                    <div><img src={ImgBeans2} alt="Coffee Beans 2" /></div>
                    <div><img src={ImgBeans3} alt="Coffee Beans 3" /></div>
                </div>



            </React.Fragment>

            )


}