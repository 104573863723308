import React from "react"
import i18next from '../i18n/config';

import ImgItalo from "../img/Zasob11.png";
import ImgVero from "../img/Zasob12.png";
import ImgCoffee from "../img/Zasob10.png";
import ImgGold from "../img/paczki_osobno_gold.png";
import ImgSilver from "../img/paczki_osobno_silver.png";


export default function OurCoffees(props)
{



    return (
            <React.Fragment>

                <section id="ourcoffees">
                    <h2>{i18next.t('och2')}</h2>
                    <div class="line"></div>
                    <div class="box">
                        <div class="italo fadeInmw">
                            <img src={ImgItalo} alt="Italo coffee logo" />
                            <p>{i18next.t('ocp')}</p>
                            <img src={ImgCoffee} alt="Icons of coffee" />
                        </div>
                        <div class="italo-coffee fadeInmw">
                            <img src={ImgGold} alt="Zanoni gold coffee" />
                        </div></div>
                </section>
                <section id="ourcoffees2">

                    <div class="box fadeInmw">
                        <div class="vero fadeInmw">
                            <img src={ImgVero} alt="Vero coffee logo" />
                            <p>{i18next.t('ocp2')}
                            </p>
                            <img src={ImgCoffee} alt="Icons of coffee" />
                        </div>
                        <div class="vero-coffee">
                            <img src={ImgSilver} alt="Zanoni gold coffee" />
                        </div></div>
                </section>





            </React.Fragment>

            )


}