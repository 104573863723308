import React from "react"


//import ImgUp from "../img/UP.png"
//import ImgUpIt from "../img/SU.png"
import ImgUpP from "../img/Path1.png"
//import ImgDown from "../img/DOWN.png"
//import ImgDownIt from "../img/GIU.png"
import ImgDownP from "../img/Polygon2.png"

import CSSTopMenu from "../styles/suwak.css"


import i18next from '../i18n/config'


        const $ = require("jquery")



var nextToGoPos = 0;

export default class Suwak extends React.Component {

    constructor(props) {
        super(props);
//        this.state = {
//            nextToGoPos: 0
//        }


        if (typeof window !== `undefined`)
            setTimeout(function () {

                let allElements = document.getElementsByTagName("section");
                nextToGoPos = ($("#" + allElements[0].id).offset().top);
                nextToGoPos = nextToGoPos - 60;//top
                if ($("#" + allElements[0].id).hasClass("is-visible")) {
                } else {
                    nextToGoPos = nextToGoPos - 150;//effect
                }



            }, 20);

//        var nextToGoPos = 22;



        setInterval(function () {
            Suwak.showSuwak();
        }, 500);






        setInterval(function () {
            if (typeof window !== `undefined`)
                $("#banerimg").css("marginTop", ($(window).scrollTop() - $(".baner").position().top));
        }, 2);





        if (typeof window !== `undefined`) {

            document.addEventListener('keydown', function (e) {
                switch (e.keyCode) {
                    case 38:
                        Suwak.UpClick();
                        break;
                    case 40:
                        Suwak.DownClick();
                        break;
                }
            });
        }
//        setTimeout(function () {
//            Suwak.showSuwak();
//        }, 166);
//
//
//        if (typeof window !== `undefined`) {
//            $(window).on("scroll", function (e) {
//                Suwak.showSuwak();
//            });
//        }









        if (typeof window !== `undefined`)
            $(document).ready(function () {
//            $('section').animate({'opacity': '0'}, 11);

                /* Every time the window is scrolled ... */

                if (typeof window !== `undefined`)
                    $(window).scroll(function () {




                        $('section').each(function (i) {
                            var bottom_of_object = $(this).position().top;
                            var bottom_of_window = $(window).scrollTop() + $(window).height();
                            $(this).removeClass("is-visible");
                            if (bottom_of_window > bottom_of_object) {
                                $(this).addClass("is-visible");
                            }

                        });
                        $('.fadeInmw').each(function (i) {
                            var bottom_of_object = $(this).position().top;
                            var bottom_of_window = $(window).scrollTop() + $(window).height();
                            $(this).removeClass("is-visible");
                            if (bottom_of_window > bottom_of_object) {
                                $(this).addClass("is-visible");
                            }

                        });




                    });

//            $('html, body').animate({
//                scrollTop: 0
//            }, 0, function () {
//            });

                $("#suwakUp").hide(0);
                $("#suwakUpP").hide(0);

                $("#suwakDown").hide(0);
                $("#suwakDownP").hide(0);

                $(window).delay(666).scroll();



                var last_window_width = $(window).width();


                window.addEventListener('resize', function () {
                    if (Math.abs(last_window_width - $(window).width()) > 22) {
                        last_window_width = $(window).width();

                        $('html, body').animate({
                            scrollTop: (window.scrollY - 5)
                        }, 800, function () {
                            Suwak.showSuwak();
                        });
                    }
                });




                $(window).scroll(function () {


//                    $("#banerimg").css("marginTop", ($(window).scrollTop() - $(".baner").position().top));
//                    $(".baner").animate({
//                        scrollTop: ($(window).scrollTop() - $(".baner").position().top) + 300
//                    }, 1, function () {
//                        // if you need a callback function
//                    });



//                    var c = document.getElementById("banerc");
//                    var ctx = c.getContext("2d");
//                    var img = document.getElementById("banerimg");
//
//
//
//                    ctx.save();
//                    ctx.translate(-111, 0);
//                    ctx.drawImage(img, 10, ($(window).scrollTop() - $(".baner").position().top));
//
//                    ctx.restore();
//  <canvas id="banerc" />

                });












                $(document).ready(function () {
                    $(".lds-ring").delay(777).hide(555);
                });



//            $('html, body').delay(222).animate({
//                scrollTop: 0
//            }, 800, function () {
//                Suwak.showSuwak();
//            });



            });















//        document.addEventListener(
//    'scroll',
//    function(event){
//        var $elm = $(event.target);
//        if( $elm.is('div.dxgvHSDC + div')){ // or any other filtering condition
//            // do some stuff
//            console.log('scrolling');
//        }
//    },
//    true // Capture event
//);



//        this.setState(postmp => {
//            return {nextToGoPos: 1};
//        });
//        this.showSuwak = this.showSuwak.bind(this);

    }

    static showSuwak()
    {



//last?

        let scrollTop = window.scrollY;
        let docHeight = document.body.offsetHeight;
        let winHeight = window.innerHeight;
        let scrollPercent = scrollTop / (docHeight - winHeight);
        let scrollPercentRounded = Math.round(scrollPercent * 100);
        if (scrollPercentRounded > 95) {
            $("#suwakDown").hide(999);
            $("#suwakDownP").hide(999);
        } else {
            $("#suwakDown").show(999);
            $("#suwakDownP").show(999);
        }


        if (scrollPercentRounded > 8) {
            $("#suwakUp").show(999);
            $("#suwakUpP").show(999);
        } else {
            $("#suwakUp").hide(999);
            $("#suwakUpP").hide(999);
        }






//#menu active
        let activeMenuName;
        let allElements = document.getElementsByTagName("section");
        if (allElements.length == 0)
            return 0;
        nextToGoPos = ($("#" + allElements[0].id).offset().top);
        nextToGoPos = nextToGoPos - 60;//top
        if ($("#" + allElements[0].id).hasClass("is-visible")) {
        } else {
            nextToGoPos = nextToGoPos - 150;//effect
        }


//        this.setState({nextToGoPos: postmp});


        let pos;
//        $(".MenuTtem").removeClass("MenuActive");
//        $('.MenuTtem').css("color", "");
        if (window.scrollY > 444) {
            for (var i = 0; i < allElements.length; ++i) {
//                console.log("for...", i);
                pos = ($("#" + allElements[i].id).offset().top);
                if (pos - 444 > window.scrollY) {
//                    this.setState({nextToGoPos: allElements[i].id.offset().top});
//                    nextToGoPos = allElements[i].id.offset().top;
//                    nextToGoPos = $("#" + allElements[i].id).offset().top - 272;

                    let allElements = document.getElementsByTagName("section");
                    nextToGoPos = ($("#" + allElements[i].id).offset().top);
                    nextToGoPos = nextToGoPos - 60;//top
                    if ($("#" + allElements[i].id).hasClass("is-visible")) {
                    } else {
                        nextToGoPos = nextToGoPos - 150;//effect
                    }





                    break;
                }
                activeMenuName = "#Menu_" + allElements[i].id;

            }
            var forMenuName;
            for (var i = 0; i < allElements.length; ++i) {
                forMenuName = "#Menu_" + allElements[i].id;


                if (forMenuName == activeMenuName) {
//                    console.log(forMenuName, activeMenuName);

                    $(forMenuName).css("color", "black");

                } else {
                    $(forMenuName).css("color", "");
                }



            }





        } else {
            $('.MenuTtem').css("color", "");
//            this.setState({nextToGoPos: $("#" + allElements[0].id).offset().top});
            let allElements = document.getElementsByTagName("section");
            nextToGoPos = ($("#" + allElements[0].id).offset().top);
            nextToGoPos = nextToGoPos - 60;//top
            if ($("#" + allElements[0].id).hasClass("is-visible")) {
            } else {
                nextToGoPos = nextToGoPos - 150;//effect
            }

        }
















//        var canvas = document.getElementById('banerimg');
//        var context = canvas.getContext('2d');
//        var x = (($(window).scrollTop() - $(".baner").position().top) - canvas.width);

//        var y = (($(window).scrollTop() - $(".baner").position().top) - canvas.height);

//
//
//        var img = new Image();
//        img.src = canvas.src;
//
//        console.log(y);
//        context.drawImage(img, x, y);
//









//        var to = $(window).scrollTop() - $(".baner").position().top
//        console.log(to);
////        $('.banerMobile').scrollTop(to);
//
//
//
//        $('.banerMobile').stop(true, true).animate({
//            scrollTop: to
//        }, 333);






//        $('.baner').animate({
//            background - position - y: ($(window).scrollTop() - $(".baner").position().top);
//        }, 666, function () {
//        });

//        $('.baner').css('background-position-y', ($(window).scrollTop() - $(".baner").position().top) + 'px');
    }

    static UpClick()
    {
        $('html, body').animate({
            scrollTop: 0
        }, 800, function () {
            Suwak.showSuwak();
        });
//        document.location.href = "#top";
    }
    static DownClick()
    {
//        let allElements = document.getElementsByTagName("section");
//        let pos;
//        var i = 0;
//        for (i = 0; i < allElements.length; ++i) {
//            pos = ($("#" + allElements[i].id).offset().top);
//            if (pos - 111 > window.scrollY) {
//                break;
//            }
//
//        }
////        pos = pos - 215;
////        alert(i);
//        if (i == 0) {
//            pos = pos - 190;
//
//        } else {
//            pos = pos - 215;
//
//        }

        $('html, body').animate({
            scrollTop: nextToGoPos
        }, 800, function () {
            Suwak.showSuwak();
        });
    }

    render() {
        const ImgSuwakUp = require("../img/" + i18next.t('SuwakUp') + "");
        const ImgSuwakDown = require("../img/" + i18next.t('SuwakDown') + "");



        return (
                <div className="suwak">
                    <img src={ImgUpP} href="javascript:void(0);" id="suwakUpP" className="icon" onClick={Suwak.UpClick} alt="UP" />
                    <br />
                    <img src={ImgSuwakUp} href="javascript:void(0);" id="suwakUp" className="icon" onClick={Suwak.UpClick} alt="UP" />
                    <br />
                    <div></div>
                    <br />
                    <img src={ImgSuwakDown} href="javascript:void(0);" id="suwakDown" className="icon" onClick={Suwak.DownClick} alt="Down" />
                    <br />
                    <img src={ImgDownP} href="javascript:void(0);" id="suwakDownP" className="icon" onClick={Suwak.DownClick} alt="Down" />
                </div>

                )
    }

}
