import React from "react"


import ImgLogo from "../img/logo.png"
import i18next from '../i18n/config';
import CSSTopMenu from "../styles/contact.css"

        const $ = require("jquery");


var myClassName = 'red';


export default class Contact extends React.Component {

//export default function Contact(props)
//{


    constructor(props) {

        super(props);
        this.state = {
            loggedIn: false
        }






    }
    sendMail = (e) => {

        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        if (typeof window !== `undefined`) {


            const sendButton = document.getElementById('send-button');
            $(sendButton).html(i18next.t('Sending...'));


            var isError = false;



            if ($("#name").val().length < 3) {
                isError = true;

                document.getElementById("name").className = 'formError';
                setTimeout(function () {
                    document.getElementById("name").className = 'formErrorOK';
                }, 1000);
            }


            if ($("#Comment").val().length < 11) {
                isError = true;
                document.getElementById("Comment").className = 'formError';
                setTimeout(function () {
                    document.getElementById("Comment").className = 'formErrorOK';
                }, 1000);
            }





            if (
                    $("#email").val().length > 7
                    ||
                    $("#telephone").val().length > 7
                    ) {
            } else {
                isError = true;

                document.getElementById("email").className = 'formError';
                document.getElementById("telephone").className = 'formError';
                setTimeout(function () {
                    document.getElementById("email").className = 'formErrorOK';
                    document.getElementById("telephone").className = 'formErrorOK';

                }, 1000);
            }







            if (isError) {
                $(sendButton).html(i18next.t('SEND'));

                document.getElementById("formErrorInfo").className = 'formErrorInfoSchow';
                setTimeout(function () {
                    document.getElementById("formErrorInfo").className = 'formErrorInfoHiden';
                }, 1000);

            } else {



                var myform = document.getElementById('myform')
                var formValues = $(myform).serialize();

                $.post("/api/sendmail.php", formValues, function (data) {
                    $(sendButton).html(i18next.t('Message sent!'));


                    document.getElementById("email").value = '';
                    document.getElementById("telephone").value = '';
                    document.getElementById("company").value = '';
                    document.getElementById("name").value = '';
                    document.getElementById("Comment").value = '';



                });


            }







        }











    }

    render() {
        return (
                <React.Fragment>





                    <section id="contact">

                        <h5>{i18next.t('h5contact')}</h5>
                        <div class="line"></div>
                        <p>
                            {i18next.t('pcontact')}
                            <br />
                            {i18next.t('pcontact2')}
                            <br />
                            {i18next.t('pcontact3')}
                        </p>
                        <form id="myform"  method="post" action="#send" onSubmit={this.sendMail}>

                            <div class="form">
                                <label for="name">{i18next.t('contactname')}</label>
                                <br />
                                <input type="text" name="Name" id="name" className={myClassName} />
                                <label for="company">{i18next.t('contactcompany')}</label>
                                <br />
                                <input type="text" name="Company" id="company" />
                                <label for="email">{i18next.t('contactemail')}</label>
                                <br />
                                <input type="email" name="E-mail" id="email" />
                                <label for="telephone">{i18next.t('contacttel')}</label>
                                <br />
                                <input type="tel" name="Telephone" id="telephone" />
                                <label for="comment">{i18next.t('contactcomment')}</label>
                                <br />
                                <input type="textarea" name="Comment" id="Comment" />
                            </div>

                            <div id="formErrorInfo" class="formErrorInfoHiden">
                                {i18next.t('Please correct the form')}


                            </div>

                            <button type="submit" id="send-button"  >{i18next.t('SEND')}</button>

                        </form>
                    </section>





                </React.Fragment>
                )
    }

}
