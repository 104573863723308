import React from "react"

//import GatsbyConfig from '../../gatsby-config'


import 'isomorphic-fetch'; //index file create

import i18next from '../i18n/config';
import Header from "../layouts/Header"
import TopMenu from "../layouts/TopMenu"
import TheOrigin from "../layouts/TheOrigin"
import OurCoffees from "../layouts/OurCoffees"
import TraditionalManufacturing from "../layouts/TraditionalManufacturing"
import TheArtOfRoasting from "../layouts/TheArtOfRoasting"
import TheArtOfRoastingGallery from "../layouts/TheArtOfRoastingGallery"
import ItalianSpeciality from "../layouts/ItalianSpeciality"
import ItalianSpecialityGallery from "../layouts/ItalianSpecialityGallery"


import Contact from "../layouts/Contact"
import Footer from "../layouts/Footer"

import Suwak from "../layouts/Suwak"




import '../styles/global.css';
import '../styles/font_lato.css';
import '../styles/efekty.css';
import '../styles/cookie.css';


import { Helmet }
from "react-helmet"
import favicon from '../img/favi_icon_16px.png';

//import imglisciemobile from '../img/lisciemobile.jpg';
//import imglisciemobile from '../img/coffee-1548766_1920_640px.jpg';






const $ = require("jquery")











//
//const null1 = require("../js/jquery.backgroundpos.js")
//



export default function Home()
{
    const GA = require("../js/googleanalytic.js");
    return (
            <React.Fragment>
                <div className="App" >
                    <Helmet>
                        <meta charSet="utf-8" />
                        <link rel="canonical" href="http://caffezanoni.it/" />
                        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                        <title>Zanoni Coffee</title>
                        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />


                        <script async src="https://www.googletagmanager.com/gtag/js?id=G-8NH5QHJ6E9"></script>


            

                    </Helmet>





                    <div class="wrapper">

                        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>



                        <TopMenu/>
                        <Header/>
                        <TheOrigin/>
                        <br />
                        <div className="baner"  >



                        </div>
                        <div className="banerMobile"  >
                        </div>
                        <OurCoffees/>
                        <TraditionalManufacturing/>
                        <TheArtOfRoasting/>
                        <TheArtOfRoastingGallery/>
                        <ItalianSpeciality/>
                        <ItalianSpecialityGallery/>


                        <Contact/>
                        <Footer/>
                        <Suwak/>
                        
                    </div>

                </div>



            </React.Fragment>

            )
}
