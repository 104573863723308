

import ImgLogo from "../img/logo.png"
import ImgIt_flag from "../img/it_flag.png"
import ImgGb_flag from "../img/gb_flag.png"




import CSSTopMenu from "../styles/TopMenu.css"
import fontMenu from "../styles/fontMenu.css"

import i18next from '../i18n/config';
import {Link, Trans, useTranslation, useI18next} from 'gatsby-plugin-react-i18next';
import React from "react"

import { render, ReactDOM } from 'react-dom';
import deepForceUpdate from 'react-deep-force-update';
//import { withTrans } from '../i18n/withTrans'


const $ = require("jquery")



export default class  TopMenu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            count: 1
        };



        this.gotosection = this.gotosection.bind(this);

//        this.changeLanguageToItClick = this.changeLanguageToItClick.bind(this);
    }

    changeLanguageToItClick()
    {
        i18next.changeLanguage('it', (err, t) => {
        }
        );

        setTimeout(function () {
            document.location.href = "#1";
        }, 33);
        setTimeout(function () {
            document.location.href = "#top";
        }, 66);

    }
    changeLanguageToEnClick()
    {
        i18next.changeLanguage('en', (err, t) => {
        }
        );

        setTimeout(function () {
            document.location.href = "#1";
        }, 33);
        setTimeout(function () {
            document.location.href = "#top";
        }, 66);

    }

    menuClick()
    {


        var myTopnav = document.getElementById("myTopnav");
        var topFlagi = document.getElementById("topFlagi");
        var cross = document.getElementById("cross");
        var bars = document.getElementById("bars");


        if (myTopnav.className === "topnav") {
            myTopnav.className += " responsive";
            topFlagi.className += " responsive";
            $(".logo").hide(111);
            cross.className += "responsive";
            $(cross).show(111);
            $(bars).hide(111);

        } else {
            myTopnav.className = "topnav";
            topFlagi.className += "topFlagi";
            $(".logo").show(111);
            $(cross).hide(111);
            $(bars).show(111);
        }



    }

//    handleClick = () => {
//        console.log(1);
//    }
//    ;
    gotosection(id) {



        var pos = ($("#" + id).offset().top);
        pos = pos - 60;//top
        if ($("#" + id).hasClass("is-visible")) {
        } else {
            pos = pos - 150;//effect
        }

        $('html, body').animate({
            scrollTop: pos
        }, 800, function () {

        });


        var myTopnav = document.getElementById("myTopnav");

        if (
                myTopnav.className != "topnav"
                &&
                "topheader" != id) {
            this.menuClick();
        }
    }

    render()
    {
        return (
                <div id="top" class="fadeInmw">



                    <div className="logo">
                        <img onClick={this.gotosection.bind(this, "topheader")} src={ImgLogo} alt="Logo Zanoni" />
                    </div>



                    <div className="topnav" id="myTopnav">

                        <a onClick={this.gotosection.bind(this, "theorigin")} id="Menu_theorigin" className=" MenuTtem" >{i18next.t('m1')}</a>
                        <a onClick={this.gotosection.bind(this, "ourcoffees")} id="Menu_ourcoffees" className=" MenuTtem">{i18next.t('m2')}</a>
                        <a onClick={this.gotosection.bind(this, "traditionalmanufacturing")} id="Menu_traditionalmanufacturing" className=" MenuTtem" >{i18next.t('m3')}</a>
                        <a onClick={this.gotosection.bind(this, "theartofroasting")} id="Menu_theartofroasting" className=" MenuTtem" >{i18next.t('m4')}</a>
                        <a onClick={this.gotosection.bind(this, "italianspeciality")} id="Menu_italianspeciality" className=" MenuTtem" >{i18next.t('m5')}</a>
                        <a onClick={this.gotosection.bind(this, "contact")} id="Menu_contact" className=" MenuTtem"  >{i18next.t('m6')}</a>
                        <a id="Menu_theorigin"  href="javascript:void(0);" className="icon" onClick={this.menuClick}>

                            <div id="bars"><i className="fa fa-bars"></i></div>
                            <div id="cross" class="hid"><i className="fa fa-times"></i></div>
                        </a>

                    </div>

                    <div  id="topFlagi" class="topFlagi">

                        <img onClick={this.changeLanguageToEnClick} src={ImgGb_flag} />

                        <img  onClick={this.changeLanguageToItClick} src={ImgIt_flag} />

                    </div>







                </div>

                )
    }

}


//export default withTrans(Layout)
