import React from "react"


import ImgLogo from "../img/Zasob3.png";
import ImgPaczki from "../img/paczki.png";
import i18next from '../i18n/config';


export default function Header(props)
{



    return (
            <React.Fragment>




                <header id="topheader" className=" fadeInmw ">


                    <div class="slogan  ">

                        <div class="title  ">
                            <img src={ImgLogo} alt="Background for slogan" />
                            <div class="line"></div>
                            <p>
                                {i18next.t('hp')}
                            </p>
                        </div>

                    </div>
                    <div class="hero fadeInmw ">
                        <img src={ImgPaczki} alt="Zanoni coffee cans" />
                    </div>


                </header>




            </React.Fragment>

            )


}