import i18next from 'i18next';

i18next.init({
    fallbackLng: 'en',
    resources: {
        it: {
            translation: require('../locales/it/translation.json')
        },
        en: {
            translation: require('../locales/en/translation.json')
        }
    },
    ns: ['translation'],
    defaultNS: 'translation',
    returnObjects: true,
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
        escapeValue: false, // not needed for react!!
    },
    react: {
        wait: true,
    },
});

i18next.languages = ['it', 'en'];
i18next.loadLanguages(['it', 'en'], (err) => { /* resources have been loaded */
});

export default i18next;