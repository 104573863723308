import React from "react"


import i18next from '../i18n/config';

const $ = require("jquery")




function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = ";Secure; HttpOnly; expires=" + date.toUTCString();
    }
    if (typeof document !== `undefined`)
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
function getCookie(name) {
    var nameEQ = name + "=";
    if (typeof window !== `undefined`) {
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ')
                c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0)
                return c.substring(nameEQ.length, c.length);
        }
    }
    return null;
}


// export default function Footer(props)
export default class Footer extends React.Component {
    OkClick() {


        var date = new Date();
        date.setTime(date.getTime() + (300 * 24 * 60 * 60 * 1000));
        let expires = ";expires=" + date.toUTCString();

        // if (typeof window !== `undefined`)
        window.document.cookie = "CookieOK=off" + expires + "; path=/";
        //setCookie("CookieOK", "off", 300);
// var btn = document.getElementById('OK');
// var cks = document.querySelector("cookies");

        //  btn.classList.add('off');
        // cks.classList.add('off');
        $(".cookies").remove();
    }

    render() {
        const  cok = getCookie("CookieOK");
        return (
                <React.Fragment>


                    <footer>  <p>{i18next.t('FooterText')}</p>
                    </footer>


                    {cok === "off" ? (
                                    <div class="cookies off">
                                
                                    </div>
                                ) : (
                                    <div class="cookies">
                                        <p>
                                            {i18next.t('CookieText')}
                                            <button onClick={this.OkClick} id="OK">OK!</button>
                                        </p>
                                    </div>
                                )}





                </React.Fragment>

                )

    }
}


