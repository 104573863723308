import React from "react"
import i18next from '../i18n/config';

import ImgItalyMap from "../img/Zasob5.png"


export default function TheOrigin(props)
{



    return (
            <React.Fragment>


                <section id="theorigin">
                <div><h2>{i18next.t('toh2')}</h2>
                    <div class="line"></div>
                    <div>
                        <p>{i18next.t('top1')}
                            <br /><br />
                            {i18next.t('top2')}
                            <br /><br />
                            {i18next.t('top3')}
                         
                        </p>
                    </div></div>
                    <div class="map">
                        <img src={ImgItalyMap} alt="Gold Italy Map" />
                    </div>
                </section>




            </React.Fragment>

            )


}